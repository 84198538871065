import { Mint } from './Mint'
import { ConnectWalletButton } from './connect/connect-wallet-button'

export function Layout () {
  return (
    <>
      <div className="bg">
        <div className="container">
          <div className="menu pt-4">
            <div className="row">
              <div className="col-6">
                <a href="https://discord.com/invite/doodfellaz" target="_blank"><img
                  src="assets/images/discord-app-icon.png" className="img-fluid img-s"/></a>
                <a href="https://twitter.com/theogdoodfellaz/" target="_blank"><img
                  src="assets/images/twiiter-app-icon.png" className="img-fluid img-s"/></a>
                <a href="https://opensea.io/collection/theogdoodfellaz" target="_blank"><img
                  src="assets/images/opensea-app-icon.png" className="img-fluid img-s"/></a>
              </div>
              <div className="col-6 text-right">
                <ConnectWalletButton/>
              </div>
            </div>
          </div>
        </div>
        {/* */}
        <div className={'w-100 mx-auto d-flex justify-content-center'}>
          <img src="assets/images/text_bubble.png" className="img-fluid mx-auto img-top"/>
        </div>

        <div className="header">
          <div className="container pt-5">
            <div className="text-center">
              <div className="bg-fram py-5">
                <h5 style={{ fontSize: '3vw', marginTop: '32px' }}>hello! we’re the doodfellaz.</h5>

                <Mint/>

              </div>
            </div>
          </div>
        </div>
        <div className="icons">
          <img src="assets/images/Mask%20Group%2016.png" className="img-fluid im-l"/>
          <img src="assets/images/Mask%20Group%2015.png" className="img-fluid im-r"/>
        </div>
        <div className="header">
          <div className="container pt-5">
            <div className="text-center">
              <div className="bg-fram py-5">
                <h5 className="pt-5">Who are the<br/> doodfellaz?</h5>
                <div className="row">
                  <div className="col-10">
                    <div className="pt-4 bx pb-4 text-left">
                      <h6 className="position-relative pl-5"><img src="assets/images/Mask%20Group%208.png"
                                                                  className="ic"/>100+ hand-drawn traits inspired by the
                        iconic Doodles and
                        DeadFellaz. (no stolen assets!)</h6>
                      <h6 className="position-relative pl-5 p-4"><img src="assets/images/Mask%20Group%208.png"
                                                                      className="ic"/>World’s first turnkey DAO
                        initiative!</h6>
                      <h6 className="position-relative pl-5"><img src="assets/images/Mask%20Group%208.png"
                                                                  className="ic"/>100% for the community by the
                        community.</h6>
                    </div>
                  </div>
                </div>
                <div className="pb-5"/>
              </div>
            </div>
          </div>
        </div>
        {/* */}
        <div className="icons">
          <img src="assets/images/Mask%20Group%2013.png" className="img-fluid im-l"/>
          <img src="assets/images/Mask%20Group%2012.png" className="img-fluid im-r"/>
        </div>
        {/* */}
        <div className="container pt-5">
          <div className>
            <div className="bg-fram2 py-5">
              <img src="assets/images/Mask%20Group%209.png" className="img-fluid im-side"/>
              <div className="text-center pt-5">
                <h5>0% sold out</h5>
                <div className="pt-4 bx pb-4 text-left">
                  <h6 className="position-relative pl-5"><img src="assets/images/Mask%20Group%208.png" className="ic"/>Discord
                    server is opened.</h6>
                  <h6 className="position-relative pl-5 p-4"><img src="assets/images/Mask%20Group%208.png"
                                                                  className="ic"/> Mods are chosen among OG members.
                  </h6>
                  <h6 className="position-relative pl-5"><img src="assets/images/Mask%20Group%208.png" className="ic"/>The
                    fun begins!</h6>
                </div>
                <div className="pt-5">
                  <div className="py-lg-5"/>
                  <h5>100% sold out</h5>
                </div>
                <div className="bx text-left pb-5 pt-4">
                  <h6 className="position-relative pl-5"><img src="assets/images/Mask%20Group%208.png" className="ic"/>The
                    Council of Doods is elected.</h6>
                  <h6 className="position-relative pl-5 p-4"><img src="assets/images/Mask%20Group%208.png"
                                                                  className="ic"/>DoodFellaz DAO is established. </h6>
                  <h6 className="position-relative pl-5"><img src="assets/images/Mask%20Group%208.png" className="ic"/>Ownership
                    transferred to community.</h6>
                </div>
                <div className="py-lg-5"/>
                <div className="py-5"/>
              </div>
            </div>
            <div className="text-center">
              <img src="assets/images/Mask%20Group%2011.png" className="img-fluid img-bottom"/>
            </div>
          </div>
        </div>
        <div className="pt-5">
          <div className="container">
            <div className="row mb text-center">
              <div className="col-6 ">
                <img src="assets/images/Mask%20Group%2019.png" className="img-fluid"/>
              </div>
              <div className="col-6 ">
                <img src="assets/images/Mask%20Group%2018.png" className="img-fluid"/>
              </div>
            </div>
            <div className="bg-fram3 text-center py-5">
              <div className="py-5">
                <h5>MORE OF US:</h5>
                <div className="icon">
                  <a href="https://discord.com/invite/doodfellaz" target="_blank"><img
                    src="assets/images/discord-app-icon.png" className="img-fluid"/></a>
                  <a href="https://twitter.com/theogdoodfellaz/" target="_blank"><img
                    src="assets/images/twiiter-app-icon.png" className="img-fluid"/></a>
                  <a href="https://opensea.io/collection/theogdoodfellaz" target="_blank"><img
                    src="assets/images/opensea-app-icon.png" className="img-fluid"/></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
import { useWeb3React } from '@web3-react/core'
import { ConnectWalletButton } from './connect/connect-wallet-button'
import { useEffect, useRef, useState } from 'react'
import { doesOwnAny, getNumberMinted, getTotalSupply, mint, parseLogs } from './connect/functions'
import settings from './settings'

export function TotalSupply () {
  const { account, library } = useWeb3React()

  const [currentSupply, setCurrentSupply] = useState()

  const fetchSupply = async function () {
    if (account) {
      const s = await getTotalSupply(library, account)

      setCurrentSupply(Number(s))
    }
  }

  useEffect(() => {
    if (account) {
      fetchSupply()
    }

    setInterval(() => {
      fetchSupply()
    }, 3000)
  }, [])

  useEffect(() => {
    if (account) {
      fetchSupply()
    }
  }, [account])

  return (
    <>
      <span className={'h5'}>
        {currentSupply}/10,000 minted – 0.01 each
      </span>
    </>
  )
}

export function MintStep () {
  const { account, library } = useWeb3React()
  const [isEligible, setIsEligible] = useState(undefined)
  const [remaining, setRemaining] = useState(undefined)
  const [mintedTokens, setMintedTokens] = useState(undefined)
  const [transaction, setTransaction] = useState()
  const [message, setMessage] = useState(undefined)
  const amountRef = useRef(null)

  const checkOwnerships = async () => {
    if (account) {
      const doesOwn = await doesOwnAny(library, account)

      setIsEligible(doesOwn)
    }
  }

  const checkNumberMinted = async () => {
    if (account) {
      const mintedAmount = await getNumberMinted(library, account)

      console.log(mintedAmount)

      setRemaining(40 - Number(mintedAmount))
    }
  }

  useEffect(() => {
    checkOwnerships()
    checkNumberMinted()

    setInterval(() => {
      checkNumberMinted()
    }, 3000)
  }, [])

  useEffect(() => {
    checkOwnerships()
    checkNumberMinted()
  }, [account])

  const handleMint = async () => {
    setTransaction(undefined)
    setMintedTokens(undefined)

    const amount = Number(amountRef.current.value)

    try {

      const tx = await mint(library, account, amount, isEligible)

      setTransaction(tx)

      const receipt = await tx.wait()

      const receiptLogs = parseLogs(library, receipt)

      const tokenIds = receiptLogs
        // .filter(log => log.event === 'Transfer')
        .map(receiptLog => receiptLog.args[2].toString())

      setTransaction('vibe')
      setMintedTokens(tokenIds)
    } catch (e) {
      // e.code === 4001 - User denied transaction signature.
      console.error(e)

      if (e.code === 4001) {
        if (message !== undefined) {
          setMessage(undefined)
        }
      } else if (e.code === 'INSUFFICIENT_FUNDS') {
        setMessage(['red', 'Insufficient funds for transaction cost']) //  (gas * price + value)
      } else {
        const errorDetailIndex = e.message.indexOf(' (error=')
        if (errorDetailIndex !== -1) {
          setMessage(['red', e.message.substring(0, errorDetailIndex)])
        } else {
          setMessage(['red', e.message])
        }
      }
    }
  }

  return (
    <>
      <div className={'d-flex flex-column justify-center px-5 pb-5 pt-0 sm:px-0 sm: pb-0'}>
        <div className={'d-flex flex-column align-items-center justify-content-center'}>
          <div className={'h5 mb-3'}>
            {isEligible ? <>
              Congratulations! <br/>
              You're a valuable owner of a Doodle or DeadFellaz! <br/>
              For each 5 doodfellaz you buy one of them is free!
            </> : <>
              If you own any Doodle or DeadFellaz, <br/>for each 5 doodfellaz you buy one of them is free!
            </>}
          </div>
        </div>
        <div className={'d-flex justify-content-center align-items-center my-3 w-100 flex-column sm:flex-row'}>
          <div className={'h4 sm:mr-3'}>Amount:</div>
          {remaining && <select ref={amountRef} className={'h4 px-2 py-0'} style={{ minWidth: '160px' }}>
            {new Array(remaining).fill(1).map((_, i) => (
              <option key={i}
                      value={i + 1}>{i + 1} {isEligible && Math.floor((i + 1) / 5) > 0 ? `(${Math.floor((i + 1) / 5)} free!)` : ''}</option>
            ))}
          </select>}
          <div style={{ height: '50px', transform: 'translateY(-4px)' }} className={'sm:pl-2'}>
            <a href="#mint" onClick={() => {
              handleMint()
            }}>
              <img src="/assets/images/mint-us.svg" alt="" style={{ height: '100%' }}/>
            </a>
          </div>
        </div>
        {
          message && <div className={'my-3'}>
            <span className={'h5'} style={{
              color: message[0]
            }}>
              {message[1]}
            </span>
          </div>
        }
        {
          transaction && transaction !== 'vibe' && <div className={'my-3'}>
            <span className={'h5'}>
              <a href={`${settings.contractEtherscanUrl}/tx/${transaction.hash}`} target={'_blank'}>Transaction submitted! Please wait for confirmation</a>
            </span>
          </div>
        }
        {
          transaction && transaction === 'vibe' && <div className={'my-3'}>
            <span className={'h4'}>
              !vibe
            </span>
          </div>
        }
        {
          mintedTokens && <>
            <div className={'mb-3 mx-auto'} style={{maxHeight: '180px', overflowY: 'scroll', width: '420px'}}>
              {
                mintedTokens.map(
                  tokenId => [
                    tokenId,
                    `${settings.contractOpenseaUrl}/assets/${settings.contractAddress}/${tokenId}`
                  ]
                ).map(([tokenId, openseaUrl]) => (
                  <div key={tokenId} className={'my-2'}>
                    <a href={openseaUrl} target={'_blank'} rel="noreferrer" className={'btn-'}>View DoodFellaz
                      #{tokenId} on OpenSea</a> <br/>
                  </div>
                ))
              }
            </div>
          </>
        }
        <TotalSupply/>
      </div>
    </>
  )
}

export function Mint () {
  const { account, library } = useWeb3React()

  if (!account) {
    return <div className="pt-4 pb-4">

      <ConnectWalletButton/>
    </div>
  }

  return (
    <div className="pt-4 pb-4">
      <MintStep/>
    </div>
  )
}

import { ethers } from 'ethers'
import { fetchFromBackend } from '../functions'
import contractAbi from '../connect/contract.abi.json'
import settings from '../settings'

export function getContract(library) {
  const contract = new ethers.Contract(settings.contractAddress, contractAbi, library)

  if (settings.env === 'development') {
    window.contract = contract
  }

  return contract
}

export function getDoodles(library) {
  const contract = new ethers.Contract(settings.doodlesContractAddress, contractAbi, library)

  return contract
}

export function getDeadFellaz(library) {
  const contract = new ethers.Contract(settings.deadfellazContractAddress, contractAbi, library)

  return contract
}

export async function doesOwnAny(library, address) {
  const doodles = getDoodles(library)
  const deadfellaz = getDeadFellaz(library)

  const doodleBalance = await doodles.balanceOf(address)
  const deadfellazBalance = await deadfellaz.balanceOf(address)

  return Number(doodleBalance) || Number(deadfellazBalance)
}

export async function getTotalSupply(library, address) {
  const contract = getContract(library)

  return contract.totalSupply.call()
}

export async function getNumberMinted(library, address) {
  const contract = getContract(library)

  return await contract.numberMinted(address)
}

export async function mint(library, address, amount, isEligible) {
  const contract = getContract(library)
  const signer = library.getSigner(address)

  let price = 10000000 * amount

  if (isEligible) {
    let freeAmount = Math.floor(amount / 5)

    price -= 10000000 * freeAmount
  }

  const tx = await contract.connect(signer).publicSaleMint(
    amount,
    {
      value: ethers.utils.parseUnits(
        String(price),
        'gwei',
      ).toHexString(),
    }
  )

  if (settings.env === 'development') {
    window.lastTx = tx
  }

  return tx
}


export function parseLogs(library, receipt) {
  const contract = getContract(library)

  const logs = receipt.logs.map(x => contract.interface.parseLog(x))

  return logs
}

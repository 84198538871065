import './App.css';
import { Layout } from './layout'

import { Web3ReactProvider } from '@web3-react/core'
import { getLibrary } from './functions'
import settings from './settings'

import { ModalProvider } from 'react-context-modals'
import './Modal.css'

function App() {
  return (
    <>
      <Web3ReactProvider getLibrary={getLibrary}>
        <ModalProvider animated>
          <Layout/>
        </ModalProvider>
      </Web3ReactProvider>
    </>
  );
}

export default App;

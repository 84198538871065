import { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useModal } from 'react-context-modals'

import { Balance, ConnectWalletModal } from './connect-wallet-modal'

export const ConnectWalletButton = ({ handleMouseEnter, handleMouseLeave, mobile }) => {
  const { account, library, deactivate, connector } = useWeb3React()
  const { showModal } = useModal()
  const [ens, setENS] = useState()

  const handleWalletConnect = () => {
    showModal(ConnectWalletModal)
  }

  useEffect(() => {
    (async () => {
      if (account) {
        const name = await library.lookupAddress(account)
        setENS(name)
      }
    })()
  }, [account, library])

  const accountString = ens ? ens : (account ? account.substring(0, 4) + '...' + account.substring(38) : '')
  const balance = Balance()

  return <>
    {
      account ?
        <>
          <h4 href="#disconnect" className={'shuriken'} rel="noreferrer" style={{
            cursor: 'pointer'
          }} alt={'Click to disconnect'} title={'Click to disconnect'}
             onClick={(e) => {
               e.preventDefault()

               deactivate()
               try {
                 connector.close()
               } catch (e) {
                 console.error(e)
               }
             }}
          >
            {accountString} <br/>
            <small>{balance}</small>
          </h4>
        </>
        :
        <>
          <a href="#connect" className={'shuriken'} rel="noreferrer"

             onClick={(e) => {
               e.preventDefault()

               handleWalletConnect()
             }}
          >
            <img src="assets/images/btn-top.svg" className="img-fluid img-btn"/>
          </a>
        </>
    }
  </>
}
